import React from 'react';
import { isLoadingStatus } from '../../stores/Status.js';

const SearchResultsDelegate = ({ query, status, hasResults, ResultComponent }) => {
  const isLoading = isLoadingStatus(status);
  const hasQuery = query && query.length;

  if (isLoading && hasQuery) {
    return <div className="search__no-results">Searching...</div>;
  }

  if (!hasResults && hasQuery) {
    return <div className="search__no-results">No results found for "{query}".</div>;
  }

  return <ResultComponent />;
};

export default SearchResultsDelegate;
