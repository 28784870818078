import { CLEAR, SET_COMPETITIONS, SET_QUERY } from './CompetitionsReducer.js';
import ApiService from '../../services/ApiService.js';

export const setQuery = query => ({
  type: SET_QUERY,
  query,
});

const setCompetitions = competitions => ({
  type: SET_COMPETITIONS,
  competitions,
});

export const clear = () => ({
  type: CLEAR,
});

export const searchForCompetitions = query => async dispatch => {
  const { results = [] } = await ApiService.getInstance().searchForCompetitions(query);
  dispatch(setCompetitions(results));
};
