import React from 'react';

const TallContainer = ({ children }) => {
  return (
    <div className="layout__container-tall">
      {children}
    </div>
  );
};

export default TallContainer;
