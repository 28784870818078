import * as React from 'react';
import { useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import Layout from '../../components/Layout.js';
import TallContainer from '../../components/TallContainer.js';
import SearchForm from '../../components/forms/SearchForm.js';
import SearchResult from '../../components/search/SearchResult.js';
import SearchResults from '../../components/search/SearchResults.js';
import SearchResultsDelegate from '../../components/search/SearchResultsDelegate.js';
import { clear, searchForCompetitions, setQuery } from '../../stores/Competitions/CompetitionsActions.js';
import { useCompetitions } from '../../stores/Competitions/CompetitionsSelectors.js';
import { useSportById } from '../../stores/Sports/SportsSelectors.js';
import { useCallback } from 'react';

const CompetitionSearchForm = () => {
  const dispatch = useDispatch();
  const { query } = useCompetitions();

  const debouncedSearch = useCallback(debounce(q => dispatch(searchForCompetitions(q)).then(), 300), []); // eslint-disable-line react-hooks/exhaustive-deps

  const onChange = q => {
    dispatch(setQuery(q));

    if (!q || !q.length) {
      dispatch(clear());
    } else {
      debouncedSearch(q);
    }
  };

  return (
    <SearchForm
      placeholder="Search for a competition..."
      onChange={onChange}
      value={query}
    />
  );
};

const CompetitionSearchResult = (
  {
    result: {
      competition: { id: competitionId, name: competitionName } = {},
      area: { name: areaName } = {},
      sport: { id: sportId } = {},
    },
  },
) => {
  const sport = useSportById(sportId);

  return (
    <SearchResult
      sport={sport}
      href={`/banners/sports/${sport.id}/competitions/${competitionId}`}
    >
      {competitionName}
      <span className="search-results__small-text">
        {areaName ? `(${areaName})` : `(World)`}
      </span>
    </SearchResult>
  );
};

const CompetitionSearchResults = () => {
  const { competitions } = useCompetitions();

  return (
    <SearchResults>
      {competitions.map(result => (
        <CompetitionSearchResult key={`comp-${result.competition?.id}`} result={result} />
      ))}
    </SearchResults>
  );
};

const CompetitionSearch = () => {
  const { query, status, competitions = [] } = useCompetitions();

  return (
    <TallContainer>
      <CompetitionSearchForm />
      <SearchResultsDelegate
        query={query}
        status={status}
        hasResults={competitions.length}
        ResultComponent={CompetitionSearchResults}
      />
    </TallContainer>
  );
};

const CompetitionBannersPage = () => {
  return (
    <Layout title={{ black: 'Configure', blue: 'Competition Banners' }}>
      <CompetitionSearch />
    </Layout>
  );
};

export default CompetitionBannersPage;
