import { createSelector } from 'reselect';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const createCompetitionsSelector = () => createSelector(
  ({ competitions }) => competitions,
  competitions => competitions,
);

export const useCompetitions = () => {
  const selector = useMemo(createCompetitionsSelector, []);
  return useSelector(selector);
};
