import React from 'react';
import UserInput from './inputs/UserInput.js';

const SearchForm = ({ value, onChange, placeholder }) => {
  const onsubmit = e => {
    e.preventDefault();
  };

  return (
    <form onSubmit={onsubmit} className="search-form">
      <UserInput type="text">
        <UserInput.Label text="" />
        <UserInput.Input value={value} onTextChange={onChange} placeholder={placeholder} />
      </UserInput>
    </form>
  );
};

export default SearchForm;
